import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, ActivatedRoute} from '@angular/router';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { MatGridListModule } from '@angular/material/grid-list';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, MatGridListModule, RouterModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  isXSmall = false;
  isSmall = false;
  isRegistration = false;
  isSignIn = false;
  type:any;
  
  constructor(
    @Inject( MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService,
    private responsive: BreakpointObserver,
    private query: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        if (this.authService.instance.getAllAccounts().length > 0) {
          this.router.navigate(['applications']);  
        }
      });
    
    this.responsive.observe([
        Breakpoints.XSmall
      ])
      .subscribe(result => {
        this.isXSmall = false;

        if (result.matches) {
          this.isXSmall = true;
        }
      });

    this.responsive.observe([
        Breakpoints.Small
      ])
      .subscribe(result => {
        this.isSmall = false;

        if (result.matches) {
          this.isSmall = true;
        }
      });


      this.type = this.query.snapshot.queryParamMap.get('type');
      if (this.type == "registration"){
        this.isRegistration = true;
      }
      if (this.type == "signin"){
        this.isSignIn = true;
      }
      

  }
  
  checkAndSetActiveAccount(){
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
}
