<div class="welcome-container">
  <div>
    <p class="paragraph-connect" *ngIf="isRegistration === false && isSignIn === false" [ngClass]="{'xsmall': isXSmall, 'small': isSmall}">Erbe Connect</p>

    <p class="paragraph-welcome" *ngIf="isRegistration === false && isSignIn === false" [ngClass]="{'xsmall': isXSmall, 'small': isSmall}">The platform where you can connect to all your Erbe applications</p>

    <p class="paragraph-userinfo" *ngIf="isRegistration === true" [ngClass]="{'xsmall': isXSmall, 'small': isSmall}">Registration successful!<br>Approval pending.<br>Meanwhile, use the same account to check your status on Erbe Connect:
      </p>
    
    <p class="paragraph-userinfo" *ngIf="isSignIn === true" [ngClass]="{'xsmall': isXSmall, 'small': isSmall}">Sorry, no access to this application.<br>If access is already requested, the approval process is underway. We'll notify you once we granted access to you.<br>Meanwhile, use the same account to check your status on Erbe Connect:
      </p>

    <p class="paragraph-connect_small" *ngIf="isRegistration === true || isSignIn === true" [ngClass]="{'xsmall': isXSmall, 'small': isSmall}">Erbe Connect</p>

    <button mat-flat-button (click)="login()" class="login-button">Sign in/up</button>

  </div>
</div>